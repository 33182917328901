import { computed, ref, watch, onUnmounted } from "vue";
import { useQuery } from "@vue/apollo-composable";
import {
  Block,
  Block_block_Block,
  BlockVariables,
} from "@/api/blocks/__generated__/Block";
import { blockDetailsGql } from "@/api/blocks/blockDetails";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { formatPoolLabel } from "@/shared/utils/stringHelpers";
import { formatDate, formatTime } from "@/shared/utils/date";

export type BlockDetailsDisplay = ReturnType<typeof blockDetailsDisplayMapper>;

const blockDetailsDisplayMapper = (item: Block_block_Block | null) => {
  if (!item) return null;

  return {
    blockId: item?.id,
    block: item?.hash,
    epoch: item?.epochNo,
    slot: item?.slotNo,
    date: formatDate(item?.timestamp),
    time: formatTime(item?.timestamp),
    pool: formatPoolLabel(
      item?.slotLeader?.poolHash?.name,
      item?.slotLeader?.poolHash?.ticker,
      item?.slotLeader?.poolHash?.hash
    ),
    transactionsCount: item?.transactionsCount ?? "0",
    transactionsAmount: item?.transactionsAmount ?? "0",
    transactionsFee: item?.transactionsFee ?? "0",
    blockLoadPercentage: item?.blockLoadPercentage * 100,
  };
};

export const useGetBlockDetails = (hash) => {
  const blockDetailsDisplay =
    ref<ReturnType<typeof blockDetailsDisplayMapper>>();
  const blockDetails = useQuery<Block, BlockVariables>(blockDetailsGql, () => ({
    hash,
  }));

  const parsedGqlBlockDetails = computed(() =>
    parseGqlResponse<Block_block_Block>("Block", blockDetails?.result?.value)
  );

  watch(parsedGqlBlockDetails, (newParsedGqlBlockDetails) => {
    // FIXME: Workaround about issue on transactionsAmount being null even if API reponse
    // is being returned. It seems to be a vue watch quirk
    if (
      !newParsedGqlBlockDetails?.data ||
      (!newParsedGqlBlockDetails?.data?.transactionsAmount &&
        !newParsedGqlBlockDetails?.data?.transactionsFee)
    )
      return;

    blockDetailsDisplay.value = blockDetailsDisplayMapper(
      newParsedGqlBlockDetails.data
    );
  });

  const timerId = window.setInterval(() => {
    blockDetails.refetch({
      hash,
    });
  }, 5000);

  onUnmounted(() => {
    if (timerId) {
      window.clearInterval(timerId);
    }
  });

  return {
    mappedDataForDisplay: blockDetailsDisplay,
    isLoading: blockDetails?.loading,
  };
};
