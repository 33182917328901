import gql from "graphql-tag";

export const transactionsGql = gql`
  query Transactions($filters: TransactionFiltersInput!) {
    transactions(filters: $filters) {
      ... on TransactionResults {
        results {
          id
          block {
            id
            epochNo
            slotNo
            epochSlotNo
            slotLeader {
              id
              poolHash {
                id
                hash
                name
                ticker
              }
              hash
            }
            blockNo
            hash
            timestamp
            status
            transactionsCount
            transactionsAmount
            transactionsFee
            previousBlockHash
            nextBlockHash
          }
          fee
          hash
          amount
        }
        pageInfo {
          nextOffset
        }
      }
      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
