/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, onUnmounted, ref, watch, toRaw, Ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import {
  Transactions,
  Transactions_transactions_TransactionResults,
  Transactions_transactions_TransactionResults_results,
  TransactionsVariables,
} from "@/api/transactions/__generated__/Transactions";
import { transactionsGql } from "@/api/transactions/transactionList";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import {
  formatNullableString,
  formatPoolLabel,
} from "@/shared/utils/stringHelpers";
import { TransactionFiltersInput } from "../../../../__generated__/globalTypes";
import { formatDate, formatTime } from "@/shared/utils/date";
import { convertAmountToAda } from "@/shared/utils/numbers";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";

export type TransactionListDisplayItem = ArrayElement<
  ReturnType<typeof transactionListDisplayMapper>
>;

export const transactionListDisplayMapper = (
  itemList:
    | (Transactions_transactions_TransactionResults_results | null)[]
    | null
) => {
  return (itemList ?? []).map((item) => {
    return {
      id: item?.id,
      transactionId: item?.hash,
      transactionAmount: convertAmountToAda(item?.amount),
      transactionFee: convertAmountToAda(item?.fee),
      poolId: item?.block?.slotLeader.poolHash?.id,
      poolName: formatPoolLabel(
        item?.block?.slotLeader.poolHash?.name,
        item?.block?.slotLeader.poolHash?.ticker,
        item?.block?.slotLeader?.hash
      ),
      epoch: item?.block?.epochNo,
      slot: item?.block?.slotNo,
      date: formatDate(item?.block?.timestamp),
      time: formatTime(item?.block?.timestamp),
      blockNumber: item?.block.id,
      // Optimize hide and show of Arrow Down
      // Based on Figma UI, there's no Arrow Up in Cards
      isArrowDown: false,
    };
  });
};

// TODO: Add additional filters as needed if a screen has the need to support custom filters
export const useGetTransactionList = (
  additionalFilters: Partial<TransactionFiltersInput> = {},
  enabled: Ref<boolean> = ref(true)
) => {
  const mergedTransactionList = ref<
    ReturnType<typeof transactionListDisplayMapper>
  >([]);
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const showMoreButtonTriggered = ref(false);
  const transactionList = useQuery<Transactions, TransactionsVariables>(
    transactionsGql,
    () => ({
      filters: {
        pagination: {
          timestamp: new Date().toISOString(),
        },
        ...additionalFilters,
      },
    }),
    () => ({
      enabled: enabled.value,
    })
  );

  const parsedGqlTransactionList = computed(() =>
    parseGqlResponse<Transactions_transactions_TransactionResults>(
      "TransactionResults",
      transactionList?.result?.value
    )
  );

  watch(parsedGqlTransactionList, (newParsedGqlTransList) => {
    if (
      newParsedGqlTransList.data?.results &&
      newParsedGqlTransList.data?.results.length > 1
    ) {
      const mappedDisplay = transactionListDisplayMapper(
        newParsedGqlTransList.data.results
      );

      const mergedList = fetchMoreListMerge<
        ArrayElement<ReturnType<typeof transactionListDisplayMapper>>
      >(
        toRaw(mergedTransactionList.value),
        mappedDisplay ?? [],
        "id",
        showMoreButtonTriggered.value
      );

      mergedTransactionList.value = mergedList;
      showMoreButtonTriggered.value = false;
    }
  });

  const timerId = window.setInterval(() => {
    if (showMoreButtonTriggered.value) {
      return;
    }
    transactionList.refetch({
      filters: {
        pagination: {
          timestamp: new Date().toISOString(),
        },
        ...additionalFilters,
      },
    });
  }, 5000);

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    transactionList.refetch({
      filters: {
        pagination: {
          timestamp: new Date().toISOString(),
          offset: mergedTransactionList.value.length,
        },
        ...additionalFilters,
      },
    });
  };

  onUnmounted(() => {
    if (timerId) {
      window.clearInterval(timerId);
    }
  });

  return {
    mappedDataForDisplay: mergedTransactionList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: transactionList?.loading,
  };
};
