
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { BlockCardTransactionDetails } from "@/shared/types/cards";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import AmountText from "@/shared/components/typography/AmountText.vue";
import Tooltip from "@/shared/components/cards/Tooltip.vue";
import LabeledItem from "@/shared/components/layouts/LabeledItem.vue";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    blockTransactionDetails: {
      type: Object as PropType<BlockCardTransactionDetails>,
      required: true,
    },
  },
  setup() {
    const t = useI18n().t;
    return {
      t,
    };
  },
  components: {
    FullWrapContainer,
    LabeledItem,
    BlockCardLayoutBoxedItem,
    AmountText,
    Tooltip,
  },
});
