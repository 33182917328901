import gql from "graphql-tag";

export const blockDetailsGql = gql`
  query Block($hash: String!) {
    block(hash: $hash) {
      ... on Block {
        id
        hash

        epochNo
        slotNo
        timestamp
        slotLeader {
          id
          poolHash {
            name
            ticker
            hash
          }
        }
        blockLoadPercentage
        transactionsCount
        transactionsAmount
        transactionsFee
      }
      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
