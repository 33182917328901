import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c21f7274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block-details w-100" }
const _hoisted_2 = { class: "w-100 mb-100 mt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalSearchInput = _resolveComponent("GlobalSearchInput")!
  const _component_PageHeaderTitle = _resolveComponent("PageHeaderTitle")!
  const _component_BlockDetailsCard = _resolveComponent("BlockDetailsCard")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_BANCard = _resolveComponent("BANCard")!
  const _component_GhostListHeader = _resolveComponent("GhostListHeader")!
  const _component_BlockDetailsTransactionCard = _resolveComponent("BlockDetailsTransactionCard")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_List = _resolveComponent("List")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GlobalSearchInput),
    _createVNode(_component_PageHeaderTitle, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Block")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { class: "ant-row ant-row-center mb-30" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_BlockDetailsCard, {
              class: "mt-30",
              blockDetails: _ctx.blockDetailsForDisplay,
              loading: _ctx.isLoading
            }, null, 8, ["blockDetails", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, {
      justify: "center",
      align: "middle",
      wrap: "",
      gutter: { lg: 16 }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardDetails, (item) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            lg: item.id === 2 ? 12 : 6,
            xs: 22,
            key: item
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BANCard, {
                class: "mb-20",
                cardDetails: item,
                "unit-type": item.unitType,
                loading: _ctx.isLoading
              }, null, 8, ["cardDetails", "unit-type", "loading"])
            ]),
            _: 2
          }, 1032, ["lg"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_GhostListHeader, null, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Transactions")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { class: "ant-row ant-row-center" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_List, {
            "total-items": _ctx.transactionListForDisplay.length,
            "data-source": _ctx.transactionListForDisplay,
            loadMore: _ctx.showMoreTransactions,
            "is-show-more-loading": _ctx.showMoreIsLoadingTransactions,
            "is-loading": _ctx.isListLoading
          }, {
            renderItem: _withCtx(({ item }) => [
              _createVNode(_component_a_list_item, { class: "pb-4 pt-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_BlockDetailsTransactionCard, { blockTransactionDetails: item }, null, 8, ["blockTransactionDetails"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["total-items", "data-source", "loadMore", "is-show-more-loading", "is-loading"])
        ])
      ]),
      _: 1
    })
  ]))
}