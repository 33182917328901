
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import BlockCardLayout from "@/shared/components/cards/BlockCardLayout.vue";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";
import LabeledItem from "@/shared/components/layouts/LabeledItem.vue";
import AmountText from "@/shared/components/typography/AmountText.vue";
import { BlockDetailsDisplay } from "@/shared/composables/api/useGetBlockDetails";

export default defineComponent({
  props: {
    blockDetails: {
      type: Object as PropType<BlockDetailsDisplay>,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    const t = useI18n().t;

    return {
      t,
    };
  },
  components: {
    AmountText,
    LabeledItem,
    FullWrapContainer,
    BlockCardLayoutBoxedItem,
    BlockCardLayout,
  },
});
