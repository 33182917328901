
import { defineComponent, ref, computed, watch, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import isEmpty from "lodash/isEmpty";
import BANCard from "@/shared/components/cards/BANCard.vue";
import BlockDetailsTransactionCard from "@/shared/components/cards/BlockDetailsTransactionCard.vue";
import BlockDetailsCard from "@/shared/components/cards/BlockDetailsCard.vue";
import GhostListHeader from "@/shared/components/layouts/GhostListHeader.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import List from "@/shared/components/lists/List.vue";
import GlobalSearchInput from "@/shared/components/forms/GlobalSearchInput.vue";
import { useGetBlockDetails } from "@/shared/composables/api/useGetBlockDetails";
import { useGetTransactionList } from "@/shared/composables/api/useGetTransactionList";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const route = useRoute();

    const hash = computed(() => route.params.hash);
    const { mappedDataForDisplay: blockDetailsForDisplay, isLoading } =
      useGetBlockDetails(hash.value);

    const fetchTransactionList = ref(false);
    const filter = reactive({
      blockId: blockDetailsForDisplay.value?.blockId,
    });
    watch(blockDetailsForDisplay, (newBlockDetails) => {
      if (!newBlockDetails) return;
      // Fetch transaction list once blockId is available
      filter.blockId = newBlockDetails.blockId;
      fetchTransactionList.value = !isEmpty(newBlockDetails.blockId);
    });

    const {
      mappedDataForDisplay: transactionListForDisplay,
      showMore: showMoreTransactions,
      showMoreIsLoading: showMoreIsLoadingTransactions,
      isLoading: isListLoading,
    } = useGetTransactionList(filter, fetchTransactionList);

    const cardDetails = computed(() => {
      return [
        {
          id: 1,
          cardTitle: t("Transactions"),
          cardAmount: blockDetailsForDisplay?.value?.transactionsCount,
          unitType: "blocks",
        },
        {
          id: 2,
          cardTitle: t("Transactions Amount"),
          cardAmount: blockDetailsForDisplay?.value?.transactionsAmount,
        },
        {
          id: 3,
          cardTitle: t("Transactions Fee"),
          cardAmount: blockDetailsForDisplay?.value?.transactionsFee,
        },
      ];
    });

    return {
      t,
      blockDetailsForDisplay,
      cardDetails,
      transactionListForDisplay,
      showMoreTransactions,
      showMoreIsLoadingTransactions,
      isLoading,
      isListLoading,
    };
  },
  components: {
    BANCard,
    BlockDetailsTransactionCard,
    GhostListHeader,
    PageHeaderTitle,
    BlockDetailsCard,
    List,
    GlobalSearchInput,
  },
});
